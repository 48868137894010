<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="20">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.user_activity") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    class="w400__mobile"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :placeholder="
                      $t('message.nameGiven') +
                      ' | ' +
                      $t('message.method_type') +
                      ' | ' +
                      $t('message.browser_type') +
                      ' | ' +
                      $t('message.ip_address')
                    "
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="4" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :can_create="false"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.trackable_type.show">
                {{ columns.trackable_type.title }}
              </th>
              <th class="w50p" v-if="columns.trackable_id.show">
                {{ columns.trackable_id.title }}
              </th>
              <th v-if="columns.method_type.show">
                {{ columns.method_type.title }}
              </th>
              <th v-if="columns.user.show">
                {{ columns.user.title }}
              </th>
              <th v-if="columns.ip_address.show">
                {{ columns.ip_address.title }}
              </th>
              <th v-if="columns.browser_type.show">
                {{ columns.browser_type.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>
            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.trackable_type.show">
                <select-user-activity
                  :id="filterForm.trackable_type"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.trackable_type"
                  size="mini"
                />
              </th>

              <th v-if="columns.trackable_id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.trackable_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.trackable_id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.method_type.show">
                <el-select
                  v-model="filterForm.method_type"
                  :placeholder="$t('message.method_type')"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  filterable
                  clearable
                  :size="'mini'"
                  class="d-block"
                >
                  <el-option
                    v-for="(type, index) in method_types"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :key="'type-' + index"
                    :label="type.label"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </th>
              <th v-if="columns.user.show">
                <select-user
                  v-model="filterForm.user_id"
                  :id="filterForm.user_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  size="mini"
                />
              </th>
              <th v-if="columns.ip_address.show">
                <crm-input
                  size="mini"
                  v-model="filterForm.ip_address"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.ip_address.title"
                ></crm-input>
              </th>
              <th v-if="columns.browser_type.show">
                <crm-input
                  size="mini"
                  v-model="filterForm.browser_type"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.browser_type.title"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>
          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(permission, index) in list"
              :key="'permissions-' + index"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ permission.id }}</td>
              <td v-if="columns.trackable_type.show">
                {{ permission.trackable_type }}
              </td>
              <td v-if="columns.trackable_id.show">
                {{ permission.trackable_id }}
              </td>
              <td v-if="columns.method_type.show">
                {{ permission.method_type }}
              </td>
              <td v-if="columns.user.show">
                {{
                  permission.user
                    ? permission.user.name + " " + permission.user.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.ip_address.show">
                {{ permission.ip_address }}
              </td>
              <td v-if="columns.browser_type.show">
                {{ permission.browser_type }}
              </td>
              <td v-if="columns.created_at.show">
                {{ permission.created_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="permission"
                  :permissionShow="'permissions.show'"
                  :permissionDestroy="'permissions.destroy'"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :append-to-body="true"
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="80%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "PermissionController",
  components: { CrmUpdate },
  data() {
    return {
      test: "",
      method_types: [
        { label: "Создан", value: "POST" },
        { label: "Изменен", value: "PUT" },
        { label: "Удалено", value: "DELETE" },
        { label: "Вход", value: "LOGIN" },
        { label: "Выход", value: "LOGOUT" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      list: "userActivity/list",
      columns: "userActivity/columns",
      pagination: "userActivity/pagination",
      filter: "userActivity/filter",
      sort: "userActivity/sort",
      mode: "MODE"
    }),
    actions: function () {
      return [];
    },
  },
  methods: {
    ...mapActions({
      updateList: "userActivity/index",
      setPagination: "userActivity/setPagination",
      updateSort: "userActivity/updateSort",
      updateFilter: "userActivity/updateFilter",
      updateColumn: "userActivity/updateColumn",
      updatePagination: "userActivity/updatePagination",
      show: "userActivity/show",
      empty: "userActivity/empty",
      refreshData: "userActivity/refreshData",
    }),
  },
};
</script>

